import Chart from "chart.js/auto";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
let charts = {}
charts.grafico_avaliacaoMediaDash = {}
charts.grafico_tempoMedioDash = {}
charts.grafico_briefingDash = {}
charts.grafico_tipoCriadorDash = {}
charts.grafico_clientesMaisProjetosDash = {}
charts.grafico_clientesMaisRenovamDash = {}

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      styleSkills: {
        borderBottom: "4px solid blue",
      },
      meses: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Maio",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
     
      skillsContratadas: [
      ],

      verticaisContratadas: [
      ],

      contratosRenovadosCard: [
      ],

      contasDeletadas: [
      ],

      year: 2022,
      loading: true,
      loading_1: true,
      loading_3: true
      
    };
  },

  mounted() {
    this.apiProfessionals();
    this.apiBriefings();
    this.apiCompanies();
  },

  methods: {
    changeYear(year){
      this.loading = true
      this.loading_1 = true
      this.loading_3 = true

      charts.grafico_avaliacaoMediaDash.destroy();
      charts.grafico_tempoMedioDash.destroy();
      charts.grafico_briefingDash.destroy();
      charts.grafico_tipoCriadorDash.destroy();
      charts.grafico_clientesMaisProjetosDash.destroy();
      charts.grafico_clientesMaisRenovamDash.destroy();
      this.skillsContratadas = []
      this.verticaisContratadas = []

      this.apiProfessionals();
      this.apiBriefings();
      this.apiCompanies();

    },

    async apiBriefings(){
      let response = await axios
        .get('/api/v1/analytics/briefings?year='+this.year, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
      if(response){
        this.loading_1 = false
        this.tempoMedioDash(response);
        this.briefingDash(response);
        this.skillsAndVerticais(response);
        this.creatorsContracted(response);
        // this.contratosRenovados(response);
      }
    },

    async apiCompanies(){
      let response = await axios
      .get('/api/v1/analytics/companies', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      });

      if(response) {
        this.loading_3 = false;
        this.companies = response.data
        this.clientesMaisProjetosDash(response);
        this.clientesMaisRenovamDash(response)
        this.companiesActiv(response);
      }
    },

    async apiProfessionals(){
      let response = await axios
      .get('/api/v1/analytics/professionals?year=' + this.year, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      });
      
        if(response) {
          this.loading = false;
          this.professionals = response.data
          this.avaliacaoMediaDash(response);
          this.tipoCriadorDash(response);
        }
    },

    avaliacaoMediaDash(response) {
      var meses = [[],[],[],[],[],[],[],[],[],[],[],[]]
      var medias = []
      var mes    
      var received_reviews

      function media(arr) {
        let soma = 0;
        
        if (arr.length == 0) return 0;
          for(let i in arr) {
          soma += arr[i]
        }
        return soma/arr.length;
      }
      
      received_reviews = response.data.professionals.filter(b => b.received_reviews.length != 0)
      received_reviews.forEach(review => {
        review.received_reviews.forEach(r => {
          mes = parseInt(r.created_at.substr(5,2)) -1
          meses[mes].push(r.total)
        })
      })

      for (var i = 0; i < meses.length; i++) {
        medias.push(media(meses[i]))
      }

      for (var i = 0; i < 12; i++) {       
        if(medias[i] == 0){
         medias[i] = null
        }
      }
      const ctx = document.getElementById("avaliacaoMediaDash");
      const labels = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const data = {
        labels: labels,
        datasets: [{
          label: "Avaliação Média",
          data: medias,
          backgroundColor: "#7553E2",
          borderColor: "#7553E2",
          pointStyle: "circle",
          pointRadius: 3,
        }, ],
      };

      const avaliacaoMediaDash = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });
      charts.grafico_avaliacaoMediaDash = avaliacaoMediaDash;
    },

    tempoMedioDash(response) {
      var meses = [[],[],[],[],[],[],[],[],[],[],[],[],[]]
      var medias = []
      var mes
      var date_end
      var date_start

      function media(arr) {
        let soma = 0;
        
        if (arr.length == 0) return 0;
          for(let i in arr) {
          soma += arr[i]
        }
        return Math.round(soma/arr.length);
      }
      function formatDate(date){
        return date.replace(/-/g, '\/').replace(/T.+/, '')
      }
        
      response.data.briefings.forEach(briefing => {
        
        date_start = new Date (briefing.date_start.substr(0,10)).getTime()
        date_end = new Date(briefing.date_end.substr(0,10)).getTime()
       
        var dif_In_Time = date_end - date_start
        
        var dif = dif_In_Time / (1000 * 3600 * 24); 
        var mes = parseInt(briefing.date_start.substr(5,2))
        meses[mes].push(dif)

       
      })

      for (var i = 0; i < meses.length; i++) {
        medias.push(media(meses[i]))
      }
      for (var i = 0; i < 12; i++) {       
        if(medias[i] == 0){
         medias[i] = null
        }
      }
      const ctx = document.getElementById("tempoMedioDash");
      const labels = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const data = {
        labels: labels,
        datasets: [{
          label: "Dias",
          data: medias,
          backgroundColor: "#7553E2",
          borderColor: "#7553E2",
          pointStyle: "circle",
          pointRadius: 3,
        }, ],
      };

      const tempoMedioDash = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });
      charts.grafico_tempoMedioDash = tempoMedioDash;
    },

    briefingDash(response) {
      let novo = [];
      let proposta_segura = [];
      let renovado = [];

      for (var i = 0; i < 12; i++) {
        novo[i] = response.data.briefings.filter(b => parseInt(b.created_at.substr(5, 2)) == (i + 1) && b.renewal_parents.length == 0).length;
      }

      for (var i = 0; i < 12; i++) {
        proposta_segura[i] = response.data.briefings.filter(b => parseInt(b.created_at.substr(5, 2)) == (i + 1) && b.plan == 4).length;
      }

      for (var i = 0; i < 12; i++) {
        renovado[i] = response.data.briefings.filter(b => parseInt(b.created_at.substr(5, 2)) == (i + 1) && b.renewal_parents != 0).length;
      }
      const ctx = document.getElementById("briefingDash");

      const totalBriefingDash = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.meses,
          datasets: [{
              label: "Aprovados",
              backgroundColor: "#74E7D0",
              data: novo,
            },
            {
              label: "Reprovados",
              backgroundColor: "#F2C94C",
              data: proposta_segura,
            },
            {
              label: "Cancelados",
              backgroundColor: "#7553E2",
              data: renovado,
            },
          ],
        },
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.grafico_briefingDash = totalBriefingDash;
    },

    // tipoContratacaoDash() {
    //   const ctx = document.getElementById("tipoContratacaoDash");
    //   const tipoContratacaoDash = new Chart(ctx, {
    //     type: "bar",
    //     data: {
    //       labels: this.meses,
    //       datasets: [{
    //           label: "Aprovados",
    //           backgroundColor: "#74E7D0",
    //           data: [10, 50, 90, 80, 95, 20, 90, 90, 89, 15, 99, 30],
    //         },
    //         {
    //           label: "Reprovados",
    //           backgroundColor: "#7553E2",
    //           data: [6, 8, 10, 10, 8, 2, 9, 11, 10, 8, 10, 20],
    //         },
    //       ],
    //     },
    //     options: {
    //       plugins: {
    //         legend: false, // Hide legend
    //       },
    //     },
    //   });
    // },

    tipoCriadorDash(response) {
      let creator = [];
      let freela = [];

      for (var i = 0; i < 12; i++) {
        freela[i] = response.data.professionals.filter(c => parseInt(c.created_at.substr(5, 2)) == (i + 1) && c.type_id == 1).length;
      }

      for (var i = 0; i < 12; i++) {
        creator[i] = response.data.professionals.filter(c => parseInt(c.created_at.substr(5, 2)) == (i + 1) && c.type_id == 2).length;
      }

      const ctx = document.getElementById("tipoCriadorDash");
      const tipoCriadorDash = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.meses,
          datasets: [{
              label: "Freela",
              backgroundColor: "#74E7D0",
              data: freela,
            },
            {
              label: "Creator",
              backgroundColor: "#7553E2",
              data: creator,
            },
          ],
        },
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });
      charts.grafico_tipoCriadorDash = tipoCriadorDash;
    },

    clientesMaisProjetosDash(response) {
      var teste = []
      var colors = [
        '#3366CC',
        '#3C77AA',
        '#448888',
        '#4D9966',
        '#55AA44',
        '#5EBB22',
        '#66CC00',
        '#80CC11',
        '#99CC22',
        '#B3CC33',
        // '#CCCC44',
        // '#E6CC55',
        // '#FFCC66'
      ]
      const reducer = (accumulator, curr) => accumulator + curr;

      response.data.companies.forEach(company => {
        var array_companies = []
        var array_companies_total

        for (var i = 0; i < 12; i++) {
          array_companies[i] = company.hirings.filter(c => c.total_hirings_count != 0 && c.created_at.substr(0,4) == this.year && parseInt(c.created_at.substr(5, 2)) == (i + 1)).length
        }
        array_companies_total = array_companies.reduce(reducer)
      
        teste.push({label: company.nome_fantasia, data: array_companies, total: array_companies_total, backgroundColor: "#fff",  pointStyle: "circle", pointRadius: 0.1,})
      })
      var t = teste.filter(t => t.total != 0)

      t.sort(function (a, b) {
        if (a.total > b.total) {
          return -1;
        }          
      });

      var resultado_limit = t.slice(0,10)

      for (let i = 0; i < colors.length; i++) {
        resultado_limit[i].backgroundColor = colors[i]
      }

    Chart.register(ChartjsPluginStacked100);
    const ctx = document.getElementById("clientesMaisProjetosDash");
      const labels = ["Jan", "Fev", "Mar", "Abr", "Mai", "jun", "jul", "ago", "Set", "Out", "Nov", "Dez"];
      const data = {
        labels: labels,
        datasets: resultado_limit      
      }

      const clientesMaisProjetosDash = new Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          scales: {
            y: {
                beginAtZero: true
            },
          },
          responsive: true,
          indexAxis: "x",
          plugins: {
            legend: {
              display: true,
                position: 'bottom'
            }, // Hide legend
            position: 'bottom',
            stacked100:{
              enable:true,
              replaceTooltipLabel: false,              
            },

            legend:{      
              position: 'bottom',
              align: 'start'
            }
          },
          tooltip: {
            mode: "index",
          },
          interaction: {
            mode: "nearest",
            intersect: false,
          },
        },
      });

      charts.grafico_clientesMaisProjetosDash = clientesMaisProjetosDash
    },

    clientesMaisRenovamDash(response) {
      var teste = []
      var colors = [
        '#3366CC',
        '#3C77AA',
        '#448888',
        '#4D9966',
        '#55AA44',
        '#5EBB22',
        '#66CC00',
        '#80CC11',
        '#99CC22',
        '#B3CC33',
        // '#CCCC44',
        // '#E6CC55',
        // '#FFCC66'
      ]
      const reducer = (accumulator, curr) => accumulator + curr;

      var companies = response.data.companies.filter(c => c.hirings.length != 0)   
      
      companies.forEach(company => {
        var array_companies = []
        for (var i = 0; i < 12; i++) {
          array_companies[i] = company.hirings.filter(c => c.renewal_parents.length != 0 && c.created_at.substr(0,4) == this.year && parseInt(c.created_at.substr(5, 2)) == (i + 1)).length
        }
       
        teste.push({label: company.nome_fantasia, data: array_companies, total: array_companies.reduce(reducer), backgroundColor: '',  pointStyle: "circle", pointRadius: 0.1,})
        })

        teste.sort(function (a, b) {
          if (a.total > b.total) {
            return -1;
          }          
        });
  
      var resultado_limit = teste.slice(0,10)

      for (let i = 0; i < colors.length; i++) {
        resultado_limit[i].backgroundColor = colors[i]
      }

      Chart.register(ChartjsPluginStacked100);
      const ctx = document.getElementById("clientesMaisRenovamDash");
      const labels = ["Jan", "Fev", "Mar", "Abr", "Mai", "jun", "jul", "ago", "Set", "Out", "Nov", "Dez"];
      const data = {
        labels: labels,
        datasets: resultado_limit      
      }
  
      const clientesMaisRenovamDash = new Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          scales: {
            y: {
                beginAtZero: true
            },
          },
          responsive: true,
          indexAxis: "x",
          plugins: {
            legend: {
              display: true,
                position: 'bottom'
            }, // Hide legend
            position: 'bottom',
            stacked100:{
              enable:true,
              replaceTooltipLabel: false,              
            },

            legend:{      
              position: 'bottom',
              align: 'start'
            }
          },
          tooltip: {
            mode: "index",
          },
          interaction: {
            mode: "nearest",
            intersect: false,
          },
        },
      });

      charts.grafico_clientesMaisRenovamDash = clientesMaisRenovamDash

    },

    skillsAndVerticais(response){
      var total
      var retorno_skills
      var retorno_verticais
      var retorno     
      var array_verticais = []
      var array_skills = []
     
      function count(array_verticais){
        total = array_verticais.length
        var current = null;
        var count = 0;
        var resultado = []
        for (var i = 0; i < array_verticais.length; i++) {
            if (array_verticais[i] != current) {
                if (count > 0) {
                  resultado.push({name: current, number: count, total: total});
                }
                current = array_verticais[i];
                count = 1;
            } else {
                count++;
            }
        }
        if (count > 0) {
          resultado.push({name: current, number: count, total: total});
        }
        
        resultado.sort(function (a, b) {
          if (a.number > b.number) {
            return -1;
          }          
        });

        var resultado_limit = resultado.slice(0,10)
        return resultado_limit  
      }
      var briefings = response.data.briefings

      briefings.forEach(briefing => {        
        var verticais =  briefing.specialities.filter(a => a.category_id == 11);
        verticais.forEach(v => {
          array_verticais.push(v.pt)
          array_verticais.sort()
        })

        var skills =  briefing.specialities.filter(a => a.category_id != 11 && a.category_id != 12 );
        skills.forEach(v => {
          array_skills.push(v.pt)
        })
      })

      retorno_verticais = count(array_verticais.sort())
      retorno_skills = count(array_skills.sort())

      retorno_verticais.forEach(r =>{
        var percent = (r.number * 100)/ r.total
        this.verticaisContratadas.push({
          city: r.name,
          percent: percent,
          color: "#74E7D0",
          number: r.number
        })
      })

      retorno_skills.forEach(r =>{
        var percent = (r.number * 100)/ r.total
        this.skillsContratadas.push({
          city: r.name,
          percent: percent,
          color: "#4285F4",
          number: r.number
        })
      })
    },
    
    creatorsContracted(response){
      function formatPercent(p){
        var str = new String(p)
       return str.replace('-', '')
      }
      var contracted = ""
      var contracted_last_year = " "
      contracted_last_year = response.data.values_last_year.filter(b => b.provider_id != null).length
      contracted = response.data.briefings.filter(b => b.provider_id != null).length
     
      if(contracted_last_year == 0){
        var percent = (contracted - contracted_last_year ) * 100/ contracted
      } else {
        var percent = (contracted - contracted_last_year ) * 100/ contracted_last_year
      }

      document.getElementById("creators_contracted").innerHTML = contracted
      document.getElementById("percent_contracted").innerHTML = Math.round(formatPercent(percent))+"%"

      if(contracted < contracted_last_year){
        document.getElementById("icon_creators").classList.add("icon-number-red")
      } else{
        document.getElementById("icon_creators").classList.add("icon-number")
      }
    },

    companiesActiv(response){      
      function formatPercent(p){
        var str = new String(p)
       return str.replace('-', '')
      }

      var activ = response.data.companies.filter(b => b.deleted_at == null && b.created_at.substr(0,4) == this.year).length
      var activ_last_year = response.data.companies.filter(b => b.deleted_at == null && b.created_at.substr(0,4) == this.year - 1).length

      // crescimento (em %) = (receita do mês atual – receita do mês anterior) ÷ receita do mês anterior × 100
      if(activ_last_year == 0){
        var percent = (activ - activ_last_year) * 100 / activ
      } else {
        var percent = (activ - activ_last_year) * 100 / activ_last_year
      }
     
      document.getElementById("companies_activ").innerHTML = activ
      document.getElementById("percent_activ").innerHTML = Math.round(formatPercent(percent))+"%"

      if(activ < activ_last_year){
        document.getElementById("icon_companies").classList.add("icon-number-red")
      } else{
        document.getElementById("icon_companies").classList.add("icon-number")
      }
    },

    contratosRenovados(response){        
      var briefings = response.data.briefings
      var renewals = []
      var meses = [[],[],[],[],[],[],[],[],[],[],[],[]]
      var mes 
      var total
      const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho","Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

      briefings.forEach(briefing => {
        total = briefing.id

        briefing.renewal_parents.forEach(renewal =>{
          mes = parseInt(renewal.created_at.substr(5,2))
          meses[mes].push(parseInt(renewal.created_at.substr(5,2)))
        })

      })
      for (let i = 0; i < meses.length; i++) {
        const d = new Date(i);
        let name = monthNames[i];  
        var percent = (meses[i].length * 100)/ total

        this.contratosRenovadosCard.push({
          city: name,
          percent: percent,
          color: "#4285F4",
          number: meses[i].length+"x"
        })
      }
    }
  },
};